import { Table, Thead, Tr, Th, Td, TableContainer, Tbody } from '@chakra-ui/react';


function RankTable({ players, isGroup }) {
    return (
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Rang</Th>
                <Th>Name</Th>
                <Th>Punkte</Th>
              </Tr>
            </Thead>
            <Tbody>
              
              {
                players.map((player, idx) => (
                  <Tr key={player.id}>
                    <Td>{isGroup && player.groupRank} {!isGroup && player.rank}</Td>
                    <Td>{player.firstname} {player.lastname}</Td>
                    <Td>{player.score}</Td>
                  </Tr>
                ))
              }
              
            </Tbody>
          </Table>

        </TableContainer>
    )

}

export default RankTable;