import { useState, useEffect } from 'react';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { API, graphqlOperation } from 'aws-amplify';
import { createPlayer, updatePlayer, deletePlayer } from '../graphql/mutations';
import { listPlayers } from '../graphql/queries';

import PlayerScore from './PlayerScore';

import { Input, Container, VStack, Heading, Button, Stack, Center, useDisclosure, Tabs, TabList, TabPanels, Tab, TabPanel, } from  '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Radio,
  RadioGroup
} from '@chakra-ui/react'



function Internal({ signOut, user }) {

  const [players, setPlayers] = useState([]);

  const initialFormState = { firstname: '', lastname: '', score: 0 }
  const [formData, setFormData] = useState(initialFormState);

  const [playerToDelete, setPlayerToDelete] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [groupValue, setGroupValue] = useState('0');

  useEffect(() => {
    fetchPlayers();
  }, []);

  async function addPlayer() {
    if (!formData.firstname || !formData.lastname) return;
    formData.group = groupValue;
    console.log(formData.group);
    await API.graphql(graphqlOperation(createPlayer, {input: formData}));
    await fetchPlayers();
    setFormData(initialFormState);
  }

  async function fetchPlayers() {
    const apiData = await API.graphql(graphqlOperation(listPlayers));
    setPlayers(apiData.data.listPlayers.items);
  }

  async function updateScore(playerID, newScore) {
    if (typeof newScore === 'number' && (newScore % 1) === 0) {
      await API.graphql(graphqlOperation(updatePlayer, { input: { id: playerID, score: newScore }}));
    }
    await fetchPlayers();
  }

  async function removePlayer() {
    await API.graphql(graphqlOperation(deletePlayer, { input: { id: playerToDelete }}));
    onClose()
    await fetchPlayers();
  }

  async function showPopUp(playerID) {
    setPlayerToDelete(playerID);
    onOpen()
  }


  return (
    <Container style={{marginTop: 70}}>
      <VStack>
        <Heading pb={4}>Hallo, {user.attributes.email}</Heading>

        <Heading as="h2" size='md'>Füge hier einen neuen Spieler hinzu:</Heading>
        <Input
          onChange={e => setFormData({ ...formData, 'firstname': e.target.value})}
          placeholder="Vorname"
          value={formData.firstname}
        />
        <Input
          onChange={e => setFormData({ ...formData, 'lastname': e.target.value})}
          placeholder="Nachname"
          value={formData.lastname}
        />

        <RadioGroup onChange={setGroupValue} value={groupValue} pt={3} pb={3}>
          <Stack direction='row'>
            <Radio value='0'>SwissPing</Radio>
            <Radio value='1'>Fördergruppe</Radio>
          </Stack>
        </RadioGroup>
        
        <Button onClick={addPlayer}>Spieler hinzufügen</Button>

      </VStack>

      <Stack spacing={2}>
        <Center>
          {players.length > 0 && <Heading as="h2" size="md" mt={100}>Ändere hier den Punktestand eines Spielers:</Heading>}
          {players.length === 0 && <Heading as="h2" size="md" mt={100}>Noch keine Spieler hinzugefügt!</Heading>}
        </Center>

        <Tabs isFitted colorScheme="teal" mt={16}>
          <TabList>
            <Tab>Alle</Tab>
            <Tab>SwissPing</Tab>
            <Tab>Fördergruppe</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {
                players.map(player => (
                  <div key={player.id}>
                    <PlayerScore player={player} updateScore = {(id, score) => updateScore(id, score)} removePlayer={(id) => showPopUp(id)}/>
                  </div>
                ))
              }
              
            </TabPanel>
            <TabPanel>
              {
                players.filter(player => player.group === 0).map(player => (
                  <div key={player.id}>
                    <PlayerScore player={player} updateScore = {(id, score) => updateScore(id, score)} removePlayer={(id) => showPopUp(id)}/>
                  </div>
                ))
              }
              
            </TabPanel>
            <TabPanel>
              {
                players.filter(player => player.group === 1).map(player => (
                  <div key={player.id}>
                    <PlayerScore player={player} updateScore = {(id, score) => updateScore(id, score)} removePlayer={(id) => showPopUp(id)}/>
                  </div>
                ))
              }
            </TabPanel>
          </TabPanels>
        </Tabs>


        
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Spieler löschen?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Willst Du den Spieler wirklich löschen?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={removePlayer}>
              Löschen
            </Button>
            <Button variant='ghost' onClick={onClose}>Abbrechen</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </Container>
  );
}

export default withAuthenticator(Internal);