import { Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import { Navbar, Button } from "react-bootstrap";

import './Navigation.css'

import { Auth } from 'aws-amplify';



function Navigation() {

  async function signOut() {
      try {
          await Auth.signOut();
      } catch (error) {
          console.log('error signing out: ', error);
      }
  }

  function isUserLoggedIn() {
    Auth.currentAuthenticatedUser({
      bypassCache: false
    })
    .then(_ => { return true })
    .catch(_ => { return false});
  }

  
  return (
    <Navbar bg="light" expand="lg" fixed="top">
      <Container>
      <Navbar.Brand as={Link} to="/">WädiStar</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link as={Link} to="/">Home</Nav.Link>
          <Nav.Link as={Link} to="/internal">Internal</Nav.Link>
        </Nav>
        <Form className="d-flex">
          {isUserLoggedIn() && <Button onClick={signOut} variant="secondary">Sign Out</Button>}
        </Form>
      </Navbar.Collapse>
    </Container>

    </Navbar>
  );
}
  
export default Navigation;