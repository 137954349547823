import { useState, useEffect } from 'react';

import { listPlayers } from '../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

import RankTable from './RankTable';
import ttcw_logo from '../ttcw_logo.jpg';
import frog from '../frog.jpg';

import { Tabs, TabList, TabPanels, Tab, TabPanel, Heading, Container, VStack, Center } from '@chakra-ui/react';

function Home() {

  const [players, setPlayers] = useState([]);

  useEffect(() => {
    fetchPlayers();
  }, []);

  async function fetchPlayers() {
    const apiData = await API.graphql(graphqlOperation(listPlayers));
    let players = apiData.data.listPlayers.items;
    setPlayers(players);
  }

  function getRankedGroup(nr) {
    var correctPlayers;
    if (nr === -1) {
      correctPlayers = players.sort((a,b) => b.score - a.score);
    } else {
      correctPlayers = players.filter(player => player.group === nr).sort((a,b) => b.score - a.score);
    }

    let currentCount = -1, currentRank = 0, stack = 1;
    for (let i = 0; i < correctPlayers.length; i++) {
        const result = correctPlayers[i];
        if (currentCount !== result['score']) {
            currentRank += stack;
            stack = 1;
        } else {
            stack++;
        }
        if (nr === -1) {
          result['rank'] = currentRank;

        } else {
          result['groupRank'] = currentRank;
        }
        currentCount = result['score'];
    }


    return correctPlayers;
  }

  return (
    <Container style={{marginTop: 70}}>
      <VStack>
        <img src={ttcw_logo} alt="Logo" width={150}/>
        <Heading>Wädistar Rangliste</Heading>
      </VStack>
        

      <Tabs isFitted colorScheme="teal" mt={16}>
        <TabList>
          <Tab>SwissPing</Tab>
          <Tab>Fördergruppe</Tab>
          <Tab>Gesamt</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
          <RankTable players={getRankedGroup(0)} isGroup={true}/>
          </TabPanel>
          <TabPanel>
          <RankTable players={getRankedGroup(1)} isGroup={true}/>
          </TabPanel>
          <TabPanel>
          <RankTable players={getRankedGroup(-1)} isGroup={false}/>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Center mt={100}><img src={frog} alt="Logo" mt="20"/></Center>
    </Container>
  );
}
  
export default Home;