import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Internal from './components/Internal';
import Home from './components/Home';
import NoPage from './components/NoPage';
import Layout from './components/Layout';
import Navigation from './components/Navigation';

import { ChakraProvider } from '@chakra-ui/react'

function App() {
  return (
    <ChakraProvider bg='green.400'>
      <div className="App">
        <BrowserRouter>
          <Navigation />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="internal" element={<Internal />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </ChakraProvider>
  );
}

export default App;
