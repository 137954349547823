import { useState } from 'react';

import { Box, HStack, Spacer, Input, Button, Center } from  '@chakra-ui/react';


function PlayerScore({ player, updateScore, removePlayer }) {

    const [scoreData, setScoreData] = useState();

    const [resetData, setResetData] = useState();

    return (
        <Box borderWidth={2} borderRadius='lg' p='6'>
            <HStack paddingBottom={5}>
                <Box
                    mt='1'
                    fontWeight='semibold'
                    as='h4'
                    lineHeight='tight'
                    noOfLines={1}
                >
                    {player.firstname} {player.lastname}
                </Box>
                <Spacer />
                <Box
                    mt='1'
                    fontWeight='semibold'
                    as='h4'
                    lineHeight='tight'
                    noOfLines={1}
                >
                    {player.score}
                </Box>
            </HStack>
            <HStack pb={2}>
                <Input
                onChange={e => setScoreData(e.target.value)}
                placeholder="Punkte hinzufügen"
                value={scoreData}
                />
                <Button w={100} onClick={() => updateScore(player.id, player.score + parseInt(scoreData))}>Add</Button>
            </HStack>
            <HStack pb={2}>
                <Input
                onChange={e => setResetData(e.target.value)}
                placeholder="Punkte zurücksetzen"
                value={resetData}
                />
                <Button w={100} onClick={() => updateScore(player.id, parseInt(resetData))}>Reset</Button>
            </HStack>
            <Center>
                <Button colorScheme='red' onClick={() => removePlayer(player.id)}>Spieler löschen</Button>
            </Center>
        </Box>
    );

}

export default PlayerScore;